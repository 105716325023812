import Cookies from 'universal-cookie';
import React, {useState} from "react";

function CookieFooter() {
    const cookies = new Cookies();
    const accepted = cookies.get('cookies-accepted')
    const [num, setNum] = useState(0)
    const daysToExpire = new Date(2147483647 * 1000);

    const handleClick = () => {
        cookies.set('cookies-accepted', true, {expires: daysToExpire, secure:true})
        setNum(num + 1);
    };

    if (accepted) {
        return
    }

    return (
        <div className="CookieFooter">
          <div className="App-CookieFooter">
            <table width="100%"  >
            <tbody>
            <tr>
                <td align="left" width="80%" style={{padding: 15 + 'px'}}>
                    Cookie Policy: We use cookies to help us do things like remember you, so you won't have to constantly authenticate to use our services. Our site doesn't use any sort advertising nor tracking apps.
                </td>
                <td align="center" style={{padding: 15 + 'px'}} width="20%">
                    <button onClick={handleClick}>Accept</button>
                </td>
            </tr>
            </tbody>
            </table>    
        </div>
        </div>
    );
}
  
export default CookieFooter;
