import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  // useParams
} from "react-router-dom";//import Login from './components/Login';

// import Contacts from './contacts/Contacts';
import CookieFooter from './CookieFooter';
import ForgotPassword from './ForgotPassword'
import Header from './Header'
import Home from './Home'
// import LoginFlow from './LoginFlow';
// import Mailbox from './components/Mailbox';

import Settings from './components/settings/Settings';
// import SignUp from './sign-up/SignUp';
import UserHome from './UserHome'
import { Provider, useDispatch } from 'react-redux'
import { resetTimeout } from './app/timeoutSlice'
import useAuth from './components/useAuth';
import store from './app/store';
import useRecollection from './components/rememberMe/useRecollection';

// import Audio from './media/Audio';
// import Media from './media/Media';
import MediaAuth from './media/MediaAuth';
// import Movies from './media/Movies';
// import MovieDetail from './media/MovieDetail';

const AppWrapper = () => {
  return (
    <Provider store={store}> 
      <App />
    </Provider>
  )
}

function App() {
  const Audio = React.lazy(() => import('./media/Audio'));
  const Contacts = React.lazy(() => import('./contacts/Contacts'));
  const LoginFlow = React.lazy(() => import('./LoginFlow'));
  const Mailbox = React.lazy(() => import('./components/Mailbox'));
  const Media = React.lazy(() => import('./media/Media'));
  const Movies = React.lazy(() => import('./media/Movies'));
  const MovieDetail = React.lazy(() => import('./media/MovieDetail'));
  const SignUp = React.lazy(() => import('./sign-up/SignUp'));

  const {token, auth} = useAuth();
  const {remembered} = useRecollection();

  const dispatch = useDispatch()
  var links=null

  if (!token && !auth) {
    if ( remembered ) {
      links=<div key={token}>
        <div className="div-link"><Link to="/">Home</Link>
        </div>
        <div className="div-link"><Link to="/login">Login</Link>
        </div>
        <div className="div-link"><Link to="/sign-up">Sign Up</Link>
        </div>
        <div className="div-link">
          <Link to="/media">Media</Link>
        </div>
      </div>
    } else {
      links=<div key={token}>
      <div className="div-link"><Link to="/">Home</Link>
      </div>
      <div className="div-link"><Link to="/login">Login</Link>
      </div>
      <div className="div-link"><Link to="/sign-up">Sign Up</Link>
      </div>
    </div>
    }
  } else {
    
      links=<div key={token}>
        {/* <Provider store={store}> */}
        <div className="div-link">
          <Link onClick={() => dispatch(resetTimeout())} to="/user">Home</Link>
        </div>
        <div className="div-link">
          <Link onClick={() => dispatch(resetTimeout())} to="/contacts">Contacts</Link>
        </div>
        <div className="div-link">
          <Link onClick={() => dispatch(resetTimeout())} to="/mailbox">Mailbox</Link>
        </div>
        <div className="div-link">
          <Link onClick={() => dispatch(resetTimeout())} to="/settings">Settings</Link>
        </div>
        
        <div className="div-link">
          <Link onClick={() => dispatch(resetTimeout())} to="/media">Media</Link>
        </div>
        {/* </Provider> */}
        </div>
    
  }

  return (
    <Router>
    <div className="App">
      <Header />  
      <div  id="body-root">
      <div id="modal-root" className="modal"></div>
      <table width="100%">
        <tbody>
          <tr>
            <td width="100px" valign="top">
              {links}
              </td>
            <td>
            <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/contacts" element={<Contacts />}/>
              <Route path="/forgot-password" element={<ForgotPassword />}/>
              <Route path="/login" element={<LoginFlow />}/>
              <Route path="/mailbox" element={<Mailbox />}/>
              <Route path="/settings" element={<Settings />}/>
              <Route path="/sign-up" element={<SignUp />}/>
              <Route path="/user" element={<UserHome />}/>

              <Route path="/music" element={<Audio />}/>
              <Route path="/media" element={<Media />}/>
              <Route path="/media-auth" element={<MediaAuth />}/>
              <Route path="/movies" element={<Movies />}/>
              <Route path="/movie-detail" element={<MovieDetail />}/>
            </Routes>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <CookieFooter />
    </div>
    </Router>
  )

}

export default AppWrapper;
