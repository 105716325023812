import { useEffect, useState } from 'react';
import { AUTH_ENDPOINT } from '../api-backend';
import Cookies from 'universal-cookie';

export default function useRecollection() {
    
  const [remembered, setRemember ] = useState([{remembered:false}])

  async function doesRememberMe() {
    const cookies = new Cookies();
    const rememberme = cookies.get('rememberme')
    setRemember( false );

    fetch(AUTH_ENDPOINT, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'X-Elliott-Technologies-Device-Id': rememberme
      },
      body: JSON.stringify({
        "method": "rememberme"
      })
    })
    .then(response => {
      // console.log("status: " + response.status)  
      if (response.status !== 200) {
        setRemember( false );
        return {}
      }
      return response.json()
    })
    .then(json => {
      // console.log("trusted: " + json.trusted)
      if (json.trusted) {
        setRemember( true );
      }
      else {
        setRemember( false );
      }
    })
    .catch( err => {
      console.log("Login.loginUser: Error " + err)
      setRemember( false );
    })
  };
  
  useEffect(() => {
    console.log("in useEffect")
    doesRememberMe()
    // if ( remembered !== true) {
    //   setRemember(false)
    // }
    // console.log("useEffect recollection: " + remembered)
  }, [])

  return {
    remembered
  };

};
